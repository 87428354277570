/*==============================================
=         12. Cart & Checkout Pages Css          =
================================================*/
.bg-gray {
  background: #f4f5f7;
}
.order-table {
  line-height: 20px;
  margin-bottom: 0px;
}
.table.order-table thead th {
  border: 0px;
}
.table.order-table td,
.table.order-table th {
  padding: 0.75rem 0rem 0.75rem 0.35rem;
}
h3.cart-page-title {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 15px;
}

.header-color-gray.cart-table-content table thead > tr th {
  padding: 10px;
}

.header-color-gray.cart-table-content table tbody > tr td {
  padding: 20px 10px 20px;
}

.cart-table-content table {
  width: 100%;
}

.cart-table-content table thead > tr th {
  border-top: medium none;
  font-size: 16px;
  text-transform: capitalize;
  vertical-align: middle;
  white-space: nowrap;
  color: #000000;
  font-weight: 600;
  text-transform: capitalize;
  padding: 0 0 33px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-table-content table thead > tr th {
    padding: 0 0 33px 0;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content table thead > tr th {
    padding: 0 20px 33px 20px;
  }
}

.cart-table-content table thead > tr th .product-name {
  max-width: 400px;
}

.cart-table-content table thead > tr th .product-price {
  max-width: 300px;
}

.cart-table-content table tbody > tr td {
  font-size: 14px;
  padding: 40px 0px 40px;
  border-top: 1px solid #ddd;
}

.cart-table-content table tbody > tr td.product-remove {
  width: 50px;
}

.cart-table-content table tbody > tr td.product-remove a {
  color: #e40202;
  font-size: 20px;
}

.cart-table-content table tbody > tr td.product-img {
  width: 100px;
}

.cart-table-content table tbody > tr td.product-name {
  width: 500px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table tbody > tr td.product-name {
    min-width: 250px;
  }
}

@media only screen and (max-width: 575px) {
  .cart-table-content table tbody > tr td.product-name {
    min-width: 200px;
    padding-left: 20px;
    padding-right: 0px;
  }
}

.cart-table-content table tbody > tr td.product-name a {
  font-size: 14px;
  font-weight: 500;
}

.cart-table-content table tbody > tr td.product-name a:hover {
  color: #02abda;
}

.cart-table-content table tbody > tr td.product-price {
  min-width: 100px;
}

.cart-table-content table tbody > tr td.product-price span {
  color: #1f2226;
  font-weight: 500;
}

.cart-table-content table tbody > tr td.product-stock-status {
  min-width: 180px;
  font-weight: 500;
}

.cart-table-content table tbody > tr td.product-stock-status p {
  color: #690;
  font-size: 16px;
}

.cart-table-content table tbody > tr td.product-total {
  min-width: 70px;
}

.cart-table-content table tbody > tr td.product-total span {
  font-weight: 600;
}

.cart-table-content table tbody > tr td.price-total {
  min-width: 100px;
}

.cart-table-content table tbody > tr td.price-total span {
  font-weight: 600;
}

.cart-table-content table tbody > tr td.product-wishlist-cart {
  text-align: right;
  min-width: 250px;
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a {
  background-color: #232323;
  color: #fff;
  font-size: 16px;
  line-height: 1;
  padding: 14px 25px;
  text-transform: capitalize;
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a:hover {
  background-color: #dcb14a;
}

.cart-shiping-update-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #cdcdcd;
  padding: 18px 0 0;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper {
    display: block;
  }
}

.cart-shiping-update-wrapper .discount-code {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.cart-shiping-update-wrapper .discount-code input {
  padding: 0 115px 0 0;
  border: none;
  height: 34px;
  font-size: 12px;
  color: #282828;
  background-color: transparent;
  border-bottom: 1px solid #d8d8d8;
}

.cart-shiping-update-wrapper .discount-code button {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border: none;
  padding: 0;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
}

.cart-shiping-update-wrapper .cart-clear a {
  font-weight: 600;
  display: inline-block;
  margin: 3px 0 0 7px;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper .cart-clear {
    margin-top: 20px;
  }
}

.grand-total-wrap {
  border: 1px solid #bfbfbf;
  background: #f9f9f9;
  padding: 0px 30px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grand-total-wrap {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .grand-total-wrap {
    margin-top: 40px;
  }
}

.grand-total-wrap h4 {
  font-weight: 800;
  font-size: 14px;
  margin: 0;
}

.grand-total-wrap .grand-total-content {
  padding: 30px 0 30px;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.grand-total-wrap .grand-total-content ul li {
  font-weight: bold;
  border-bottom: 1px solid #cdcdcd;
  padding-bottom: 16px;
  margin-bottom: 17px;
}

.grand-total-wrap .grand-total-content ul li:last-child {
  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.grand-total-wrap .grand-total-content ul li span {
  float: right;
  font-weight: 400;
}

.grand-total-wrap .grand-btn {
  margin-left: -30px;
  margin-right: -30px;
}

.grand-total-wrap .grand-btn a {
  background-color: #262626;
  color: #fff;
  text-align: center;
  font-size: 14px;
  display: block;
  padding: 23px 10px 20px;
}

.grand-total-wrap .grand-btn a:hover {
  background-color: #dcb14a;
}

.discount-code {
  max-width: 400px;
}

.discount-code input {
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid #ccc;
  color: #000;
  border-radius: 0;
  padding: 15px 15px 13px;
}

.cart_totals {
  max-width: 400px;
}

/*-------- 26. Checkout style ---------*/
.customer-zone > p {
  position: relative;
  padding: 14px 30px 14px 60px;
  background-color: #f4f5f7;
  margin: 0;
}

.customer-zone > p::before {
  content: "\f058";
  color: #6dde6d;
  display: inline-block;
  position: absolute;
  font-family: fontawesome;
  left: 30px;
}

.checkout-login-info {
  border: 1px solid #ddd;
  padding: 30px;
  display: none;
  margin-top: 30px;
}

.checkout-login-info p {
  color: #777;
  margin: 0 0 22px;
}

.checkout-login-info .single-input input {
  border: 1px solid #cdcdcd;
  height: 50px;
  background-color: transparent;
  width: 100%;
  color: #777;
  font-size: 14px;
  padding: 0 20px;
}

.checkout-login-info .single-input input:focus {
  border: 1px solid #262626;
}

.checkout-login-info .single-input label {
  display: block;
  margin: 0 0 10px;
}

.checkout-login-info .single-input label span {
  color: red;
}

.checkout-coupon-info {
  border: 1px solid #ddd;
  padding: 30px;
  display: none;
  margin-top: 30px;
}

.checkout-coupon-info p {
  color: #777;
  margin: 0 0 22px;
}

.checkout-coupon-info form input[type="text"] {
  border: 1px solid #cdcdcd;
  height: 50px;
  background-color: transparent;
  width: 100%;
  color: #777;
  font-size: 14px;
  padding: 0 20px;
}

.checkout-coupon-info form input[type="text"]:focus {
  border: 1px solid #262626;
}

.checkout-coupon-info form input[type="submit"] {
  background: #262626 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  height: 50px;
  margin-top: 15px;
  padding: 5px 30px 7px;
  text-transform: capitalize;
  font-weight: 400;
  width: inherit;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .checkout-coupon-info form input[type="submit"] {
    padding: 5px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-coupon-info form input[type="submit"] {
    padding: 5px 16px;
    margin-left: 5px;
  }
}

.checkout-coupon-info form input[type="submit"]:hover {
  background-color: #dcb14a;
}

.billing-info-wrap .billing-info label {
  display: block;
  margin: 0 0 10px;
}

.billing-info-wrap .billing-info label span {
  color: red;
}

.billing-info-wrap .billing-info input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #777;
  width: 100%;
}

.billing-info-wrap .billing-info input:focus {
  border: 1px solid #262626;
}

.billing-info-wrap .billing-info input.billing-address {
  margin-bottom: 20px;
}

.billing-info-wrap .billing-select label {
  display: block;
  margin: 0 0 10px;
}

.billing-info-wrap .billing-select label span {
  color: red;
}

.billing-info-wrap .billing-select .select-active {
  border: 1px solid #e6e6e6;
  color: #262626;
  border-radius: 0;
  /* background: transparent url(../images/icons/selector-icon.png) no-repeat
    center right 20px !important; */
}

.billing-info-wrap .billing-select .select-active:focus {
  background: transparent;
}

.billing-info-wrap .billing-select .select2-container {
  display: block;
}

.billing-info-wrap .billing-select .select2-container .select2-choice {
  border: 1px solid #e6e6e6;
  background: transparent;
  -moz-appearance: none;
  height: 50px;
  padding: 2px 20px;
  color: #262626;
  cursor: pointer;
}

.billing-info-wrap
  .billing-select
  .select2-container
  .select2-choice
  .select2-chosen {
  height: 50px;
}

.billing-info-wrap .additional-info-wrap {
  margin: 3px 0 0;
}

.billing-info-wrap .additional-info-wrap label {
  display: block;
  margin: 0 0 10px;
  padding: 5px 0 0;
}

.billing-info-wrap .additional-info-wrap textarea {
  min-height: 120px;
  background-color: transparent;
  border-color: #e8e8e8;
  padding: 20px;
  color: #8a8a8a;
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .billing-info-wrap.mr-100 {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .billing-info-wrap.mr-100 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .billing-info-wrap.mr-100 {
    margin-right: 0px;
  }
}

.select2-container--default .select2-selection--single {
  border: 1px solid #e6e6e6;
  border-radius: 0px;
  cursor: pointer;
  display: block;
  height: 50px;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  line-height: 50px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  height: 50px;
  color: #262626;
  line-height: 50px;
  padding: 0px 20px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 50px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  height: 34px;
  background-color: transparent;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #0073aa;
  color: #fff;
}

.your-order-area {
  padding: 40px 45px 50px;
  border-width: 2px;
  border-style: solid;
  position: relative;
  border: 1px solid #bfbfbf;
  background-color: #f6f6f6;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .your-order-area {
    padding: 40px 25px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .your-order-area {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .your-order-area {
    padding: 40px 12px 50px;
    margin-top: 30px;
  }
}

.your-order-area h3 {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  margin: 0 0 45px;
}

.your-order-area h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -14px;
  background-color: #000;
  width: 50px;
  height: 2px;
}

.your-order-area .your-order-wrap .your-order-info {
  border-bottom: 1px solid #cdcdcd;
}

.your-order-area .your-order-wrap .your-order-info ul li {
  padding: 0 0 7px;
}

.your-order-area .your-order-wrap .your-order-info ul li span {
  float: right;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal ul li span {
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-info.order-total {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-total ul li span {
  font-size: 18px;
}

.your-order-area .your-order-wrap .your-order-middle {
  border-bottom: 1px solid #cdcdcd;
  padding: 24px 0 23px;
}

.your-order-area .your-order-wrap .your-order-middle ul li {
  font-size: 14px;
  margin: 0 0 11px;
}

.your-order-area .your-order-wrap .your-order-middle ul li:last-child {
  margin-bottom: 0;
}

.your-order-area .your-order-wrap .your-order-middle ul li span {
  float: right;
  font-size: 16px;
}

.your-order-area .your-order-wrap .payment-method {
  margin: 25px 0 0;
}

.your-order-area .your-order-wrap .payment-method h5 {
  text-transform: uppercase;
}

.your-order-area .your-order-wrap .payment-method p {
  color: #777;
  font-size: 12px;
  margin: 14px 0 0;
}

.your-order-area .your-order-wrap .condition-wrap {
  margin: 40px 0 0;
}

.your-order-area .your-order-wrap .condition-wrap p {
  color: #777;
  line-height: 1.88;
  margin: 0;
}

.your-order-area .your-order-wrap .condition-wrap p a {
  color: #777;
}

.your-order-area .your-order-wrap .condition-wrap .condition-form input {
  border: 1px solid #9fa0a2;
  display: inline-block;
  height: 10px;
  width: 10px;
  position: relative;
  top: 6px;
}

.your-order-area .your-order-wrap .condition-wrap .condition-form span {
  color: #262626;
  font-weight: 400;
  margin: 0 0 0 5px;
}

.your-order-area
  .your-order-wrap
  .condition-wrap
  .condition-form
  span
  span.star {
  color: red;
}

.your-order-area .Place-order a {
  background-color: #262626;
  display: block;
  cursor: pointer;
  padding: 22px 50px 21px;
  width: 100%;
  color: #fff;
  text-align: center;
}

.your-order-area .Place-order a:hover {
  background-color: #dcb14a;
}

.order-tracking-form-box input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #777;
  width: 100%;
}

.order-tracking-form-box input:focus {
  border: 1px solid #262626;
}

.order-tracking-form-box input.billing-address {
  margin-bottom: 20px;
}

/*============================================
=           02. Section Spacing Css          =
=============================================*/
.section-space {
  /* Section Padding Css */
  /* Section Margin Css */
}

.section-space--ptb_120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_120 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_120 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_120 {
  padding-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_120 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_120 {
    padding-top: 60px;
  }
}

.section-space--pb_120 {
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_120 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_120 {
    padding-bottom: 60px;
  }
}

.section-space--ptb_100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_100 {
  padding-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_100 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_100 {
    padding-top: 60px;
  }
}

.section-space--pb_100 {
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_100 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_100 {
    padding-bottom: 60px;
  }
}

.section-space--ptb_90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_90 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_90 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.section-space--pt_90 {
  padding-top: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_90 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_90 {
    padding-top: 40px;
  }
}

.section-space--pb_90 {
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_90 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_90 {
    padding-bottom: 40px;
  }
}

.section-space--ptb_80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_80 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_80 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.section-space--pt_80 {
  padding-top: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_80 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_80 {
    padding-top: 40px;
  }
}

.section-space--pb_80 {
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_80 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_80 {
    padding-bottom: 40px;
  }
}

.section-space--ptb_70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_70 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_70 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.section-space--pt_70 {
  padding-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_70 {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_70 {
    padding-top: 30px;
  }
}

.section-space--pb_70 {
  padding-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_70 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_70 {
    padding-bottom: 30px;
  }
}

.section-space--ptb_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_60 {
  padding-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_60 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_60 {
    padding-top: 60px;
  }
}

.section-space--pb_60 {
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_60 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_60 {
    padding-bottom: 60px;
  }
}

.section-space--pt_40 {
  padding-top: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_40 {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_40 {
    padding-top: 30px;
  }
}

.section-space--pb_40 {
  padding-bottom: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_40 {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_40 {
    padding-bottom: 30px;
  }
}

.section-space--ptb_30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.section-space--pt_30 {
  padding-top: 30px;
}

.section-space--pb_30 {
  padding-bottom: 30px;
}

.section-space--mt_15 {
  margin-top: 15px;
}

.section-space--mt_20 {
  margin-top: 20px;
}

.section-space--mt_30 {
  margin-top: 30px;
}

.section-space--mt_40 {
  margin-top: 40px;
}

.section-space--mt_50 {
  margin-top: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_50 {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_50 {
    margin-top: 30px;
  }
}

.section-space--mt_60 {
  margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_60 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_60 {
    margin-top: 30px;
  }
}

.section-space--mt_70 {
  margin-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_70 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_70 {
    margin-top: 30px;
  }
}

.section-space--mt_80 {
  margin-top: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_80 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_80 {
    margin-top: 30px;
  }
}

.section-space--mt_100 {
  margin-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_100 {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_100 {
    margin-top: 60px;
  }
}

.section-space--mt_120 {
  margin-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_120 {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_120 {
    margin-top: 60px;
  }
}

.section-space--mb_15 {
  margin-bottom: 15px;
}

.section-space--mb_20 {
  margin-bottom: 20px;
}

.section-space--mb_30 {
  margin-bottom: 30px;
}

.section-space--mb_40 {
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .section-space--mb_40 {
    margin-bottom: 30px;
  }
}

.section-space--mb_50 {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_50 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_50 {
    margin-bottom: 30px;
  }
}

.section-space--mb_60 {
  margin-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_60 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_60 {
    margin-bottom: 30px;
  }
}

.section-space--mb_100 {
  margin-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_100 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_100 {
    margin-bottom: 60px;
  }
}

.section-space--mb_120 {
  margin-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_120 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_120 {
    margin-bottom: 60px;
  }
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .small-mt__0 {
    margin-top: 0px;
  }
  .small-mt__10 {
    margin-top: 10px;
  }
  .small-mt__20 {
    margin-top: 20px;
  }
  .small-mt__30 {
    margin-top: 30px;
  }
  .small-mt__40 {
    margin-top: 40px;
  }
  .small-mt__50 {
    margin-top: 50px;
  }
  .small-mt__60 {
    margin-top: 60px;
  }
  .small-mb__30 {
    margin-bottom: 30px;
  }
  .small-mb__40 {
    margin-bottom: 40px;
  }
  .small-mb__50 {
    margin-bottom: 50px;
  }
  .small-mb__60 {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tablet-mt__0 {
    margin-top: 0px;
  }
  .tablet-mt__30 {
    margin-top: 30px;
  }
  .tablet-mt__40 {
    margin-top: 40px;
  }
  .tablet-mt__50 {
    margin-top: 50px;
  }
  .tablet-mt__60 {
    margin-top: 60px;
  }
  .tablet-mb__30 {
    margin-bottom: 30px;
  }
  .tablet-mb__40 {
    margin-bottom: 40px;
  }
  .tablet-mb__50 {
    margin-bottom: 50px;
  }
  .tablet-mb__60 {
    margin-bottom: 60px;
  }
}

/*=====  End of spacing  ======*/
/*======================================
=        15. Button Css
=======================================*/
a,
button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a,
button,
img,
input,
span {
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.btn {
  border-radius: 0;
  /*font-size: 15px;*/
  font-weight: 500;
  height: 50px;
  /*line-height: 38px;*/
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: transparent;
}

.btn--sm {
  line-height: 38px;
  padding: 0 22px;
  height: 40px;
}

.btn--md {
  line-height: 38px;
  padding: 0 32px;
  height: 40px;
}

.btn--lg {
  line-height: 44px;
  padding: 0 42px;
  height: 46px;
}

.btn--black {
  background: #000000;
  color: #ffffff;
}

.btn--black:hover,
.btn--black:focus {
  background: #222;
  color: #ffffff;
}

.btn--border_1 {
  border: 1px solid #222;
}

.btn--border_1:hover {
  border: 1px solid #222;
  background: #222;
  color: #ffffff;
}

.btn--full {
  width: 100%;
}

.btn--icon {
  border: 1px solid #222;
  text-align: center;
  height: 46px;
  line-height: 44px;
  padding: 0 22px;
}

.btn--icon:hover {
  border: 1px solid #222;
  background: #222;
  color: #ffffff;
}

.btn--text-icon {
  font-size: 18px;
  font-weight: 500;
}

.btn--text-icon i {
  margin-right: 10px;
}

.btn--border-bottom {
  font-size: 18px;
  text-decoration: underline;
  font-weight: 500;
  line-height: 1;
}

.btn--border-bottom:hover {
  text-decoration: underline;
}

.tf-btn {
  font-size: 18px;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  padding: 0 28px;
  font-weight: 700 !important;
  text-align: center;
  background-color: #7baa0b;
  color: #fff;
}

.tf-btn:hover,
.tf-btn:focus {
  color: #ffffff;
}

@media only screen and (max-width: 575px) {
  .tf-btn {
    padding: 0 10px;
    font-size: 12px;
  }
}
